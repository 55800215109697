export const CLIENT_ID = 'highmark-amh-web';
/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'hm-allmyhealth';

// Mobile app URLs
export const APP_STORE_PROD_URL =
  'https://apps.apple.com/us/app/allmyhealth/id6473355245';
export const PLAY_STORE_PROD_URL =
  'https://play.google.com/store/apps/details?id=com.highmark.allmyhealth';

export const COB_URL = import.meta.env.VITE_COB_URL;

export const SAPPHIRE_URL =
  'https://allmyhealth.sapphirecareselect.com/?ci=AMH';

export const MEDICAL_POLICY_URL =
  'https://securecms.mybenefitshome.com/content/medpolicy/en/unbranded/commercial/search.html';
